<!---->
<template>
  <div>
    <!--    <headtitle Otitle="人才审核"/>-->
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>人才审核</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="hr-20"></div>
      <div
        style="padding: 0px;margin: 0px;overflow: hidden;border-bottom: 1px #f9f7f7 solid;"
      >
        <el-form size="mini">
          <el-row :gutter="20">
            <el-col :span="3"
              ><el-form-item>
                <el-input
                  v-model.trim="searchName"
                  placeholder="姓名"
                  clearable
                /> </el-form-item
            ></el-col>
            <el-col :span="3"
              ><el-form-item>
                <el-input
                  v-model.trim="searchPhone"
                  placeholder="手机号"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="3"
              ><el-form-item>
                <el-select
                  v-model.trim="searchSex"
                  placeholder="性别"
                  clearable
                >
                  <el-option
                    v-for="item in Sex"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3"
              ><el-form-item>
                <el-select
                  v-model="currentStatus"
                  multiple
                  placeholder="状态"
                  @change="setStatus"
                >
                  <el-option
                    v-for="item in Status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3"
              ><el-form-item>
                <el-select
                  v-model="skillCategory"
                  filterable
                  placeholder="专业领域"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in professionalFieldoptions"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3"
              ><el-form-item>
                <el-select
                  v-model="rankLevel"
                  filterable
                  placeholder="省内外"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in rankLeveloptions"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3"
              ><el-form-item class="fr">
                <el-button type="primary" plain size="mini" @click="getDate(1)"
                  ><i class="fa fa-search fa-fw"></i>查询
                </el-button>
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="requestRefresh"
                  icon="el-icon-refresh"
                  >刷新
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="3"></el-col>
          </el-row>
        </el-form>
      </div>

      <el-table
        :data="tableData"
        sortTable="custom"
        style="width: 100%"
        @row-dblclick="requestSee"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号">
          <template slot-scope="scope">
            {{ scope.row.account.contact }}
          </template>
        </el-table-column>
        <el-table-column label="状态" sortable>
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 11">通过</el-tag>
            <el-tag type="success" v-else-if="scope.row.status === 21"
              >通过</el-tag
            >
            <el-tag type="danger" v-else-if="scope.row.status === 10"
              >驳回</el-tag
            >
            <el-tag type="info" v-else>待审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="申请时间">
          <template slot-scope="scope">
            {{ scope.row.modifiedTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="requestSee(scope.row)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="size"
        @current-change="requestPage"
        id="pagination"
      />
    </el-card>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "@/utils/config";
export default {
  components: {},
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      id: sessionStorage.getItem("ID") || "",
      dialogVisible: false,
      searchCondition: {}, //搜索条件
      searchName: "", // 查询姓名
      rankLeveloptions: configDate.introduce, // 选择是否省内外
      rankLevel: "", // 是否省内外
      professionalFieldoptions: configDate.professionalField, // 选择专业领域
      skillCategory: "", // 从事专业领域
      searchPhone: "", // 查询电话号码
      searchSex: "", //查询 性别
      Sex: [
        {
          value: 0,
          label: "男",
        },
        {
          value: 1,
          label: "女",
        },
      ],
      Status: [
        {
          value: 11,
          label: "未审核",
        },
        {
          value: 21,
          label: "已通过",
        },
        {
          value: 20,
          label: "管理员驳回",
        },
      ],
      currentStatus: [],
      tableData: [],
      table: [],
      form: {
        money: "",
        remarks: "",
      },
    };
  },

  created() {
    this.getDate();
  },
  methods: {
    getDate() {
      console.log(sessionStorage.getItem("ID"));
      let self = this;
      let arr = [];
      if (self.currentStatus.length === 0) {
        arr = [1, 2, 11, 21];
      } else {
        arr = self.currentStatus;
      }
      this.searchCondition = {
        name: self.searchName,
        phone: self.searchPhone,
        statuses: arr,
        enterpriseId: this.id,
        current: this.page,
        size: self.size,
        sex: self.searchSex,
        skillCategory: self.skillCategory,
        rankLevel: self.rankLevel,
      };
      this.$axios
        .post("/talent/condition", self.searchCondition)
        .then(function(res) {
          console.log(res);
          self.tableData = res.data.data.records;
          self.total = res.data.data.total;
        });
    },
    setStatus() {
      this.currentStatus = [...this.currentStatus];
    },
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },

    requestRefresh() {
      this.page = 1;
      this.getDate();
    },
    /**
     *
     * @param value 页数
     */
    requestPage(value) {
      console.log(value);
      this.page = value;
      this.getDate();
      /* this.$axios.post('/talent/condition', {
                    enterpriseId: this.id,
                    current: value,
                    statuses: [2, 10, 11],
                    size: this.size
                }).then(response => {
                    this.tableData = response.data.data.records
                }) */
    },

    requestSee(row) {
      let newPage = this.$router.resolve({
        path: "/examinewz/" + row.id,
      });
      window.open(newPage.href, "_blank");
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.speeditem {
  h4 {
    margin: 0;
  }

  p {
    margin: 3px;
  }
}

#pagination {
  padding-top: 16px;
}

.text {
  color: #ffffff;
}
</style>
